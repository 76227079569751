import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { assetCurrencyExchangeApi } from 'api';
import {
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  dataGridColumns,
} from 'components';
import { ERROR_MESSAGE } from 'constants/common.constants';
import { QueryKey, StatusCode } from 'enums';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AssetCurrencyExchange as AssetCurrencyExchangeType } from 'types';
import { formatUtils } from 'utils';

import { AssetCurrencyExchangeDetailsDialog } from './AssetCurrencyExchangeDetailsDialog';

export const AssetCurrencyExchange: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.currencies.asset_currency_exchange',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => QueryKey.AssetCurrencyExchange, []);

  const { getAssetCurrencyCode, getCurrencyExchangeLabel } = useCurrencies();

  const queryResult = useQuery(
    queryKey,
    () => assetCurrencyExchangeApi.getAll(),
    {},
  );

  const { mutate: remove } = useMutation(assetCurrencyExchangeApi.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    notifierType: 'remove',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (
          status === StatusCode.Conflict &&
          error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
        ) {
          return tCommon('errors.in_use');
        }
      },
    },
  });

  const columns = useMemo(
    (): DataGridColumnDefinition<AssetCurrencyExchangeType>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.name'),
        valueKey: 'name',
      },
      {
        header: t('fields.currencies'),
        valueGetter: (item) =>
          `${getAssetCurrencyCode(
            item.assetCurrencyId,
          )} / ${getAssetCurrencyCode(item.crossAssetCurrencyId)}`,
      },
      {
        header: t('fields.currency_exchange'),
        valueGetter: (item) =>
          getCurrencyExchangeLabel(
            item.currencyExchange?.assetCurrencyId,
            item.currencyExchange?.fiatCurrencyId,
            item.currencyExchange?.source,
            item.currencyExchange?.name,
          ),
      },
      {
        header: t('fields.default_exchange'),
        valueKey: 'defaultExchange',
        valueFormatter: formatUtils.formatBoolean,
      },
    ],
    [getAssetCurrencyCode, getCurrencyExchangeLabel, t],
  );

  return (
    <CrudTable
      queryResult={queryResult}
      columns={columns}
      actions={[
        {
          type: CrudTableActionType.Details,
          renderDialog: AssetCurrencyExchangeDetailsDialog,
        },
        {
          type: CrudTableActionType.Remove,
          onRemove: (item, { close }) => remove(item.id, { onSuccess: close }),
        },
      ]}
    />
  );
};
