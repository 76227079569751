import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import {
  assetCurrencyExchangeApi,
  assetCurrencyExchangeRequestsApi,
} from 'api';
import { Math } from 'classes';
import {
  CloseFormikDialogResult,
  DataWrapper,
  Dialog,
  DialogProps,
  FormControls,
  FormikNumericField,
  FormikSelect,
  Money,
} from 'components';
import { QueryKey, StatusCode } from 'enums';
import { useCurrencies, useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset } from 'types';
import { validationUtils } from 'utils';

type Props = { isPlatform?: boolean } & DialogProps<Asset>;

type Values = {
  amount: number;
  assetCurrencyExchangeId?: string;
};

export const AssetExchangeDialog: React.FC<Props> = ({
  open,
  data,
  isPlatform,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.asset_exchange_dialog',
  });
  const { t: tCommon } = useTranslation();
  const {
    defaultAssetCurrency,
    getAssetCurrencyExchange,
    getAssetCurrencyExchangeOptions,
    getAssetCurrencyCode,
    getAssetCurrencySymbol,
  } = useCurrencies();

  const { isMerchant } = useUser();

  const queryResult = useQuery(
    QueryKey.UserAssetCurrencyExchange,
    () =>
      assetCurrencyExchangeApi.getUserAssetCurrencyExchange({
        id: data?.id!,
        toAssetCurrencyId: defaultAssetCurrency?.id!,
      }),
    { enabled: !!data?.id && !isPlatform },
  );

  const isPriceChangedError = useCallback((error: AxiosError<any>) => {
    const status = error?.response?.status;
    const message = error.response?.data?.message?.[0] as any;
    return (
      status === StatusCode.BadRequest &&
      message?.constraints?.invalid &&
      message?.property === 'price'
    );
  }, []);

  const mutation = useMutation<
    any,
    AxiosError,
    {
      id: string;
      toAssetCurrencyId: string;
      amount: number;
      assetAmount: number;
      price: number;
      assetCurrencyExchangeId?: string;
      isPlatform: boolean;
    }
  >(assetCurrencyExchangeRequestsApi.exchange, {
    notifierMessages: {
      error: (error: AxiosError<any>) => {
        if (isPriceChangedError(error)) {
          if (isPlatform) {
            queryClient.invalidateQueries(QueryKey.AssetCurrencyExchange);
          } else {
            queryClient.invalidateQueries(QueryKey.UserAssetCurrencyExchange);
          }
          return t('messages.price_changed');
        }
      },
    },
  });

  const initialValues = useMemo(
    (): Values => ({
      amount: 0,
      assetCurrencyExchangeId: '',
    }),
    [],
  );

  const price = useMemo(() => queryResult.data?.price, [queryResult]);

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        amount: Yup.number()
          .moreThan(0, tCommon('errors.natural_number'))
          .max(data?.balance!, `${tCommon('errors.max')} ${data?.balance!}`)
          .required(tCommon('errors.required')),
        assetCurrencyExchangeId: isPlatform
          ? Yup.string().required(tCommon('errors.required'))
          : Yup.string().optional(),
      }),
    [data?.balance, isPlatform, tCommon],
  );

  const handleSubmit = useCallback(() => {}, []);

  const getAssetAmount = useCallback((amount: number, price: number) => {
    if (!amount || !price) {
      return 0;
    }
    return new Math(amount).divide(price!).roundDown().value;
  }, []);

  const getAssetCurrencyExchangePrice = useCallback(
    (id: string) => getAssetCurrencyExchange(id)?.currencyExchange?.price!,
    [getAssetCurrencyExchange],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
        return;
      }

      const resultPrice = isPlatform
        ? getAssetCurrencyExchangePrice(
            result.data?.values.assetCurrencyExchangeId!,
          )
        : price!;

      mutation.mutate(
        {
          id: data?.id!,
          toAssetCurrencyId: defaultAssetCurrency?.id!,
          ...(isPlatform && {
            assetCurrencyExchangeId:
              result.data?.values.assetCurrencyExchangeId,
          }),
          amount: result.data?.values.amount!,
          assetAmount: getAssetAmount(
            result.data?.values.amount!,
            resultPrice!,
          ),
          price: resultPrice,
          isPlatform: !!isPlatform,
        },
        {
          onSuccess: () => {
            if (isPlatform) {
              queryClient.invalidateQueries(QueryKey.PlatformAssets);
            } else {
              queryClient.invalidateQueries(QueryKey.MyAssets);
            }
            result.data?.formikHelpers?.resetForm();
            onClose(result);
          },
          onError: (error: AxiosError) => {
            if (!isPriceChangedError(error)) {
              result.data?.formikHelpers.setErrors(
                validationUtils.getFormErrors(error),
              );
            }
          },
        },
      );
    },
    [
      isPlatform,
      getAssetCurrencyExchangePrice,
      price,
      mutation,
      data?.id,
      defaultAssetCurrency?.id,
      getAssetAmount,
      onClose,
      queryClient,
      isPriceChangedError,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('title')}
          okDisabled={!formik.isValid || (!price && !isPlatform)}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
        >
          <DataWrapper
            queryResult={isPlatform ? undefined : queryResult}
            emptyViewText={
              isMerchant ? t('messages.no_exchange_price') : undefined
            }
          >
            <Fragment>
              <Form>
                <FormControls>
                  <div>
                    {`${getAssetCurrencyCode(data?.assetCurrencyId)} → ${
                      defaultAssetCurrency?.code
                    }`}
                  </div>
                  <div>
                    <div className="tw-flex tw-align-center">
                      <div className="tw-mr-1">{`${t(
                        'fields.available',
                      )}:`}</div>
                      <Money
                        value={data?.balance!}
                        symbol
                        assetCurrencyId={data?.assetCurrencyId}
                      />
                    </div>
                    {!isPlatform && (
                      <div className="tw-flex tw-align-center">
                        <div className="tw-mr-1">{`${t(
                          'fields.exchange_rate',
                        )}:`}</div>
                        <Money
                          value={price!}
                          symbol
                          assetCurrencyId={data?.assetCurrencyId}
                        />
                      </div>
                    )}
                    <div className="tw-flex tw-align-center tw-mb-4">
                      <div className="tw-mr-1">{`${t(
                        'fields.asset_amount',
                      )}:`}</div>
                      {price && (
                        <Money
                          value={getAssetAmount(formik.values.amount, price)}
                          symbol
                          assetCurrencyId={defaultAssetCurrency?.id}
                        />
                      )}
                      {isPlatform && (
                        <Money
                          value={getAssetAmount(
                            formik.values.amount,
                            getAssetCurrencyExchangePrice(
                              formik.values.assetCurrencyExchangeId!,
                            ),
                          )}
                          symbol
                          assetCurrencyId={defaultAssetCurrency?.id}
                        />
                      )}
                    </div>
                  </div>
                  <FormikNumericField
                    label={t('fields.amount')}
                    name="amount"
                    allowNegative={false}
                    suffix={getAssetCurrencySymbol(data?.assetCurrencyId)}
                  />
                  {isPlatform && (
                    <FormikSelect
                      label={t('fields.exchange_rate')}
                      name="assetCurrencyExchangeId"
                      required
                      options={getAssetCurrencyExchangeOptions(
                        data?.assetCurrencyId!,
                      )}
                    />
                  )}
                </FormControls>
              </Form>
            </Fragment>
          </DataWrapper>
        </Dialog>
      )}
    </Formik>
  );
};
